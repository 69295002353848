import { GatsbySeo } from 'gatsby-plugin-next-seo'

import SandeverBeachTenis from '../../components/Campanha/SandeverBeachTenis'

function SandeverBeachTenisPage() {
  return (
    <>
      <GatsbySeo title="Campanha | Decathlon" noindex nofollow />
      <SandeverBeachTenis />
    </>
  )
}

export default SandeverBeachTenisPage
